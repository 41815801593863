import React, {  } from "react";
import styles from "./IncidentCategoryv2.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import { StepperSteps } from "../../../Constants/CommonConstants";
import { PrimaryButtonAdapter } from "../Controls";
import { useNavigate } from "react-router-dom";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { CustomChoiceGroup, ICustomChoiceGroupOption } from "../Controls/CustomChoiceGroup/CustomChoiceGroup";
import { trackEvent } from "../../../Services/telemetryService";
import { INCIDENT_CATEGORY_SELECTION_STEP } from "../../../Constants/InstrumentationConstants";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { IncidentCategoriesLoadingSelector } from "../../Loader/LoaderSlice";
import Loader from "../../Loader/Loader";
import { CuiAiChat, CuiAiChatElement, CuiDialog } from '@charm-ux/cui/dist/react';
import { CuiButton, CuiCard, CuiIcon } from '@charm-ux/cui/dist/react';
import { ConversationMessagesSelector, addMessage } from "../Conversation/ConversationSlice";
import { useRef, useState } from 'react';
import { DigitalIncidentTypesSelector, SelectedDigitalIncidentTypeL2Selector, DigitalIncidentInformationSelector, setDigitalIncidentIncidentDescription } from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { AiChatResponse } from "@charm-ux/cui";

export default function IncidentCategoryv2() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
	const selectedEventTypeId = 3;//digitalIncidentTypesDetails.selectedIncidentTypeL1?.typeId;
	const selectedL2IncidentType = useSelector(SelectedDigitalIncidentTypeL2Selector);
	var digitalIncidentDescription = useSelector(DigitalIncidentInformationSelector).incidentDescription;

	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	let selectedCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;
	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
    const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const handleIncidentCategoryChange = (categoryId: IncidentCategories) => {
		let incidentCategory = incidentCategoryDetails.incidentCategories.find((s) => s.categoryId === categoryId);
		dispatch(setSelectedIncidentCategory(incidentCategory));
	};

	console.log("Incident category details are: ", incidentCategoryDetails);

	const incidentCategoryChoiceGroupOptions: ICustomChoiceGroupOption[] = incidentCategoryDetails.incidentCategories.map((x) => {
		return {
			key: x.categoryId,
			moreInfoLink: x.moreInfoUrl,
			optionDescriptionLocalizationKey: x.descriptionKey,
			optionLabelLocalizationKey: x.nameKey,
		};
	})

	const isIncidentCategoriesLoading = useSelector(IncidentCategoriesLoadingSelector);

	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Category));
	}, []);

	React.useEffect(()=>{
		trackEvent({name: INCIDENT_CATEGORY_SELECTION_STEP}, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_CATEGORY_SELECTION_STEP,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
		});
	}, []);

	const messages = useSelector(ConversationMessagesSelector);

	handleIncidentCategoryChange(IncidentCategories.Digital);

	var ignoreInput = false;
	React.useEffect(() =>{
		console.log("Messages are: ", messages);
		for (let i = 0; i < messages.length; i++) {
			if (messages[i].role == "user") {
				ignoreInput = true;
				aiChatRef.current?.addInput({ value: messages[i].content.toString() });
				ignoreInput = false;
			} else {
				aiChatRef.current?.addResponse(messages[i].content as unknown as AiChatResponse);
			}
		}
	}, []);

	const aiChatRef = useRef<CuiAiChatElement>(null);

const nextButton = <div className="ms-Grid-row" style={{ marginBottom: 16, marginTop: 28 }}>
<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
	<PrimaryButtonAdapter
		text={getLocalizedValueV2("RIN_Next")}
		disabled={!selectedCategoryId}
		onClick={() => {
			var path="";
			if (selectedCategoryId == IncidentCategories.Digital) {
				path = "digital";
			}
			else {
				path = "physical";
			}

			navigate(path);
		}}
		styles={{
			root: {
				marginTop: 16,
				marginBottom: 16,
				selectors: {
					"@media (max-width: 479px)": {
						marginTop: 12,
					},
				},
			},
		}}
	/>
</div>
</div>;

	const categoryChoice = 
	<div
	className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3"
	style={{ display: "flex", flexDirection: "column" }}
>
	<div>
		<div className={styles.categoryBlock} style={{ backgroundColor: "rgba(250, 250, 250, 1)", paddingTop: 12 }}>
			<div className={styles.category + " site-margins"}>
				<div className={styles.categoryDetails}>
					<h2 aria-level={1} className={styles.categoryHeader} id="incident-category-step-heading">{getLocalizedValueV2("RIN_SelectIncidentCategory")}</h2>
					<p className={styles.categoryDescription}>
						{getLocalizedValueV2("RIN_PI_GSOC")}{" "}
						<a href="tel:+1 425 706-0000" style={{ color: "#0078D4", textDecoration: "none" }} aria-label={getLocalizedValueV2("RIN_PI_GSOC") + " +1 425 706-0000" }>
							+1 425 706-0000
						</a>
					</p>
				</div>
			</div>
			<div className="c-stepper__divider"></div>
		</div>
		<CustomChoiceGroup
			options={incidentCategoryChoiceGroupOptions}
			selectedKey={selectedCategoryId}
			name="incident categories"
			onChange={(option) => { handleIncidentCategoryChange(option!.key) }}
			legendId="incident-category-step-heading"
		/>
	</div>
	{nextButton}
	</div>

	const inputs = [
		"I suspect giving access of my subscription to some unauthorized person.", 
		"Someone had called stating that they are from support team and need access of my device to fix something.", 
		"Yes, they took control of my device."];
	const outputs = [{value: "It is unfortunate to hear. Could you pls describe that how it has happened?"}, 
		{value: "Got it. Could you please tell that did they take control of your device?"}, 
		{value: "Your incident has been categorized: Digital → Technical Support Scam.\n\n\nWe need a few more details to take appropriate action on this incident. Please click on the button to provide more details.", actions: [{id: 0, data: "digital/24/details", content: "Provide details"}]}]

	const handleInput = (e: any) => {
		// alert(e)
		if(ignoreInput) return;
		if(e.detail.value.trim() != inputs[messages.length/2].trim())
		{
			alert("Required input is: \"" + inputs[messages.length/2] + "\"");
			return;
		}
		console.log("Input is", e);
		dispatch(setDigitalIncidentIncidentDescription(digitalIncidentDescription + "\n" + e.detail.value));
		dispatch(addMessage({ role: "user", content: e.detail.value }));
		dispatch(addMessage({ role: "assistant", content: outputs[messages.length/2] }));
		aiChatRef.current?.addResponse(outputs[messages.length/2]);
	}

	const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
  const promptStyles = `
    .cards{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      gap: var(--spacing-md);
      place-items: center;
      align-items: stretch;
    }
  `;

  // Define card titles and content
  const cardsData = [
    {
      title: 'Lost an Asset',
      content:
        'I lost my _Asset_ while coming/going to _Places_. I have logged the report with the Police. I need to revoke all my access of the machine.',
    },
    {
      title: 'Gave remote access to someone wrongly',
      content:
        'I mistakenly extended the access of my _device_ to someone over a _call_ and I had shared the details of _Subscription_.',
    },
    {
      title: 'Generate 5 ideas',
      content: 'Write an article for my team on the importance of showing up to meetings on time.',
    },
    {
      title: 'Brainstorm ideas',
      content:
        'Write a 2-page article about the importance of wellbeing in English, word limit 200 characters.',
    },
  ];

  const sentenceToElements = (sentence: string) => {
	const elements = [];
	var strong = false;
	var word = "";
	for(let i = 0; i < sentence.length; i++)
	{
		if(sentence[i] == "_")
		{
			if(strong)
			{
				elements.push(<span style={{color: "grey", fontWeight: "bold"}}>{word}</span>);
			}
			else
			{
				elements.push(<strong>{word}</strong>);
			}
			word = "";
			strong = !strong;
		}
		else
		{
			word += sentence[i];
		}
	}
	if(word)
	{
		if(strong)
		{
			elements.push(<span style={{color: "grey", fontWeight: "bold"}}>{word}</span>);
		}
		else
		{
			elements.push(<strong>{word}</strong>);
		}
	}
	
	return elements;
}

const cardElements = <div>
{cardsData.map((card, index) => (
  <CuiCard
  style={{ margin: 10, cursor: "pointer" }}
  key={index}
  appearance="filled"
  onClick={() => {
	aiChatRef.current?.resetInput();
	aiChatRef.current?.addText(card.content.replaceAll('_', ''));
	setPromptLibraryOpen(false);
  }}
>
	<h3 style={{ color: "grey", marginTop: 0 }}>{card.title}</h3>
	<p>				
	  {sentenceToElements(card.content)}
	</p>
  </CuiCard>
))}
</div>;

	return (
		<div className="incidentCategory">
			{isIncidentCategoriesLoading && <Loader />}
			{/* {categoryChoice} */}
			{
				<div style={{marginLeft: "7.5%", marginRight: "7.5%", paddingTop: 20}}>
					<CuiAiChat
						ref={aiChatRef}
						onAiChatInput={handleInput}
						promptGuide
						label="response content example chat window"
						placeholder="Describe the incident"
						onAiChatAction={(e) => { navigate(e.detail.data!.toString()) }}
						onAiChatPromptGuide={() => setPromptLibraryOpen(true)}
					/>
					{
						<>
							<CuiDialog
								heading="More prompts to try"
								open={promptLibraryOpen}
								onDialogHide={() => setPromptLibraryOpen(false)}
							>
								{cardElements}
							</CuiDialog>
							<style>{promptStyles}</style>
						</>
					}
					{/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
						{nextButton}
					</div> */}
				</div>
			}
			{/* {nextButton} */}
		</div>
	);
}