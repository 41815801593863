import { AiChatResponse } from "@charm-ux/cui";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Role = "user" | "assistant";

export interface ConversationMessage {
  role: Role;
  content: string | AiChatResponse;
}

export interface ConversationState {
  messages: ConversationMessage[];
}

const initialState: ConversationState = {
  messages: [],
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<ConversationMessage>) => {
      state.messages.push(action.payload);
    }
  }
});

export const { addMessage } = conversationSlice.actions;
export default conversationSlice.reducer;
export const ConversationMessagesSelector = (state: { conversation: ConversationState }): ConversationMessage[] =>
state.conversation.messages;