/* eslint-disable react/jsx-no-undef */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, DefaultButton } from "@fluentui/react";
import { StepperSteps } from "../../../Constants/CommonConstants";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { IncidentCategorySelector } from "../../IncidentCategory/IncidentCategorySlice";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import { PhysicalIncidentDetailsv2 } from "../PhysicalSecurity/PhysicalIncidentAdditionalInformation/PhysicalIncidentDetailsv2";
import { DigitalIncidentDetailsv2 } from "../DigitalSecurity/IncidentInformationv2/DigitalIncidentDetailsv2";
import { ConversationMessagesSelector } from "../Conversation/ConversationSlice";
import { useNavigate } from "react-router-dom";

export function IncidentDetails() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const lastUserMessage = useSelector(ConversationMessagesSelector).filter((x) => x.role === "user").pop()?.content;
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const selectedIncidentCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;

	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Details));
	}, []);


	return (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
		<DefaultButton
                iconProps={{ iconName: "Edit" }}
                text={lastUserMessage || ""}
                onClick={() => {
                    navigate("/v2/create")
                }}
                styles={{
                    root: {
                        marginTop: 20,
                        border: "1px solid black",
                        borderRadius: 10,
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                    },
                    rootHovered: {
                        borderColor: "#0078d4",
                    },
                }}
            />
		<div className="incidentDetails site-margins" style={{paddingTop: 15}}>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					{selectedIncidentCategoryId == IncidentCategories.Digital && <DigitalIncidentDetailsv2 />}
					{selectedIncidentCategoryId == IncidentCategories.Physical && <PhysicalIncidentDetailsv2 />}
				</div>
			</div>
		</div>
		</div>
	);
}
