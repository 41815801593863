import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "../Sagas/rootSaga";
import serviceCommunicationSettings from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import NotificationDetails from "../Components/Notifications/NotificationsSlice";
import ApplicationSettings from "../Components/ConfigurationsPanel/ApplicationSettingsSlice";
import FileUploadDetails from "../Components/FileUpload/FileUploadSlice";
import IncidentCategory from "../Components/IncidentCategory/IncidentCategorySlice";
import Loader from "../Components/Loader/LoaderSlice";
import DigitalIncident from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import HelpSection from "../Components/HelpPanel/HelpSectionSlice";
import SimulationCampaign from "../Components/DigitalSecurity/IncidentType/SimulationCampaignSlice";
import SubmissionSuccess from "../Components/SubmissionSuccess/SubmissionSuccessSlice";
import UserProfile from "../Components/UserProfile/UserProfileSlice";
import physicalIncident from "../Components/PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import exceptionPage from "../Components/ExceptionPage/exceptionPageSlice";
import Sovereign from "../Components/Sovereign/SovereignSlice";
import TimeZonesDetails from "../Components/TimeZone/TimeZonesSlice";
import StepperDetails from "../Components/RINv2/Stepperv2/Stepperv2Slice";
import conversation from "../Components/RINv2/Conversation/ConversationSlice";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    serviceCommunicationSettings,
    NotificationDetails,
    FileUploadDetails,
    conversation,
    IncidentCategory,
    Loader,
    DigitalIncident,
    HelpSection,
    SimulationCampaign,
    SubmissionSuccess,
    UserProfile,
    physicalIncident,
    exceptionPage,
    ApplicationSettings,
    Sovereign,
    TimeZonesDetails,
    StepperDetails,
  },
  middleware: (gDM) => gDM().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
